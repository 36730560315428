/*
 * @Description: 折线图
 * @Author: chenzhen
 * @Date: 2024-06-12 17:55:13
 * @LastEditors: chenzhen
 */
import React, { useEffect, useState } from 'react';
import { ECharts, init } from 'echarts';

const LineChart = ({
  xAxisData,
  dataKey,
  dataList,
  lineChartTitle,
  width,
  height,
  formatterXAxisFun,
  containerId,
  getHistoricalLineTooltip,
  getLineChartDataList,
  axisLabelInterval, // 横坐标数据显示间隔
  dataZoom, // 滚动条一页显示几条数据
  formatterYAxisFun,
  isResize,
}: {
  xAxisData?: string;
  dataKey?: string;
  dataList;
  lineChartTitle;
  width: string | number;
  height: string | number;
  formatterXAxisFun;
  containerId: string;
  getHistoricalLineTooltip;
  getLineChartDataList;
  axisLabelInterval?: number;
  dataZoom?: number;
  formatterYAxisFun?;
  isResize?;
}) => {
  const [lineChart, setLineChart] = useState<ECharts>();

  const color = [
    '#5470c6',
    '#91cc75',
    '#fac858',
    '#ee6666',
    '#73c0de',
    '#3ba272',
    '#fc8452',
    '#9a60b4',
    '#ea7ccc',
  ];

  const seriesData = dataAll => {
    if (dataAll?.data.length === 1) {
      const seriesList = dataAll?.data.map((item, index) => {
        return [
          {
            name: item?.name || lineChartTitle,
            type: 'line',
            symbol:
              'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAVxJREFUOE9jZMACxLfcjGD4z+DEwMCgCJZmZLzHwPB//0sf9RXoyhmRBcAa//7tZGBilvv/49uf/6+eMYP1i0n9ZeTgYmH49/cRAzNzObJBcAPEt96sYPjH0P7v0Z3/f/ZvZfx78xKKZczqegwsjt7/meRUGBmYGCpfeqt3gA0HEVAnL/975jDDr3ULsPkKLsYWlMDAbGIL0hkJcgnEgI3XHv57cl/254x2FC/hMok9o/I/k4zi45f+WvKMMNt/LZzIgOxsVr8YBhZDS7AZf84fZ/i9aQncPJB32OLzwa5gFN9ya+b/71+TfjTlssBUgDVbOKI44M+J/SiGcNRN/sPIwTWfUXzzzd3/Ht1xRnY+Z/1UBgZ2DlQf/PzB8L0xGy4G9oacyl6qGDDr/49viWR5gZN7HuWBSHE0UiUhgQ2hJCnD4oaizISRKxkYHRn+/1eCit9nYGTYhy07AwBlJOVAtKWyqAAAAABJRU5ErkJggg==',
            symbolSize: 0.1,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(35,239,226,0.14)',
                  },
                  {
                    offset: 0.66,
                    color: 'rgba(35,239,226,0.1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(35,239,226,0)',
                  },
                ],
              },
              opacity: 1,
            },
            lineStyle: {
              shadowBlur: 4,
              shadowOffsetY: 2,
              shadowColor: 'rgba(0,234,219,0.26)',
            },
            emphasis: {
              label: {
                show: false,
              },
              scale: 140,
              focus: 'none',
              disabled: true,
            },
            data: item?.data, // 折线图数据
            itemStyle: {
              color: 'rgba(21, 168, 216, 1)',
            },
            label: {
              show: false,
              position: 'top',
              color: '#495057',
              fontSize: '12',
              fontStyle: 'normal',
              fontFamily: 'PingFang SC',
              fontWeight: 'normal',
            },
            labelLayout: {
              hideOverlap: true,
            },
            smooth: false,
            step: false,
            connectNulls: false,
            markArea: {},
            markLine: {
              symbol: ['none', 'none'],
              animation: false,
            },
          },
        ];
      });
      const series = seriesList.map(i => i?.[0]);
      return series;
    } else {
      const seriesList = dataAll?.data.map((item, index) => {
        return [
          {
            name: item?.name,
            type: 'line',
            symbol:
              'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAVxJREFUOE9jZMACxLfcjGD4z+DEwMCgCJZmZLzHwPB//0sf9RXoyhmRBcAa//7tZGBilvv/49uf/6+eMYP1i0n9ZeTgYmH49/cRAzNzObJBcAPEt96sYPjH0P7v0Z3/f/ZvZfx78xKKZczqegwsjt7/meRUGBmYGCpfeqt3gA0HEVAnL/975jDDr3ULsPkKLsYWlMDAbGIL0hkJcgnEgI3XHv57cl/254x2FC/hMok9o/I/k4zi45f+WvKMMNt/LZzIgOxsVr8YBhZDS7AZf84fZ/i9aQncPJB32OLzwa5gFN9ya+b/71+TfjTlssBUgDVbOKI44M+J/SiGcNRN/sPIwTWfUXzzzd3/Ht1xRnY+Z/1UBgZ2DlQf/PzB8L0xGy4G9oacyl6qGDDr/49viWR5gZN7HuWBSHE0UiUhgQ2hJCnD4oaizISRKxkYHRn+/1eCit9nYGTYhy07AwBlJOVAtKWyqAAAAABJRU5ErkJggg==',
            symbolSize: 0.1,
            lineStyle: {
              shadowBlur: 4,
              shadowOffsetY: 2,
              shadowColor: 'rgba(0,234,219,0.26)',
            },
            emphasis: {
              label: {
                show: false,
              },
              scale: 140,
              focus: 'none',
              disabled: true,
            },
            data: item?.data, // 折线图数据
            label: {
              show: false,
              position: 'top',
              color: '#495057',
              fontSize: '12',
              fontStyle: 'normal',
              fontFamily: 'PingFang SC',
              fontWeight: 'normal',
            },
            labelLayout: {
              hideOverlap: true,
            },
            smooth: false,
            step: false,
            connectNulls: false,
            markArea: {},
            markLine: {
              symbol: ['none', 'none'],
              animation: false,
            },
          },
        ];
      });
      const series = seriesList.map(i => i?.[0]);
      return series;
    }
  };

  useEffect(() => {
    if (dataList) {
      const dataAll = getLineChartDataList(dataList, lineChartTitle);

      // 折线图配置项
      const option: {
        color?;
        toolbox?;
        tooltip?;
        legend?;
        dataZoom?;
        grid?;
        xAxis?;
        yAxis?;
        series?;
        title?;
      } = {
        color: color,
        toolbox: {
          show: false,
          left: 'auto',
          right: '1%',
          top: 'auto',
          bottom: '1%',
          feature: {
            saveAsImage: {
              name: '面积图',
              backgroundColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#0c1f40',
                  },
                  {
                    offset: 1,
                    color: '#081224',
                  },
                ],
              },
              excludeComponents: ['toolbox'],
              title: '保存为图片',
              pixelRatio: 1,
              iconStyle: {
                borderColor: '#39C6F9',
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#fefefe',
                },
              },
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderWidth: 0,
          padding: 0,
          textStyle: {
            color: 'rgba(44, 214, 247, 0.8);',
          },
          extraCssText: 'box-shadow: none !important;',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: 'rgba(35,239,226, 0.75)',
            },
          },
          formatter: params => getHistoricalLineTooltip(params, lineChartTitle),
        },
        legend: {
          right: 8,
          top: 16,
          bottom: 24,
          width: 96,
          show: false,
          type: 'scroll',
          orient: 'vertical',
          height: null,
          selected: {
            lineChartTitle: true,
          },
          data: [lineChartTitle],
          textStyle: {
            color: '#495057',
            fontSize: '12',
            fontStyle: 'normal',
            fontFamily: 'PingFang SC',
            fontWeight: 'normal',
          },
          itemStyle: {
            opacity: 1,
          },
          lineStyle: {
            opacity: 1,
          },
        },
        dataZoom: dataZoom && [
          {
            type: 'slider',
            handleSize: '100%',
            xAxisIndex: 0,
            showDetail: false,
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            selectedDataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            fillerColor: 'rgba(44, 214, 247, 0.8)',
            handleStyle: {
              borderCap: 'round',
              opacity: 0,
            },
            brushSelect: false,
            height: 8,
            zoomLock: false,
            left: 'auto',
            bottom: '3',
            startValue: 0,
            endValue: dataZoom,
          },
        ],
        grid: {
          left: 18,
          right: 18,
          bottom: 13,
          top: 16,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          tooltip: {
            show: true,
          },
          data: dataAll?.xAxis, // x轴
          maxInterval: 3600 * 24 * 1000 * 31,
          axisLabel: {
            show: true,
            rotate: 0,
            overflow: 'none',
            color: 'rgba(255, 255, 255, 0.8)',
            interval: axisLabelInterval || 0,
            fontSize: 10,
            fontStyle: 'normal',
            fontFamily: 'Microsoft YaHei',
            fontWeight: 'lighter',
            lineHeight: 10,
            hideOverlap: true,
            width: -2,
            formatter: v => {
              return formatterXAxisFun(v);
            },
          },
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, 0.1)',
              width: 1,
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, 0.1)',
              width: 1,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: '#ced4da',
              width: 1,
            },
          },
        },
        yAxis: {
          type: 'value',
          name: null,
          nameLocation: 'center',
          nameGap: 20,
          nameRotate: 90,
          inverse: false,
          minInterval: 0,
          min: null,
          max: null,
          axisLabel: {
            show: true,
            color: 'rgba(255, 255, 255, 0.8)',
            fontSize: 10,
            fontStyle: 'normal',
            fontFamily: 'Microsoft YaHei',
            fontWeight: 'lighter',
            formatter: v => {
              return formatterYAxisFun ? formatterYAxisFun(v, lineChartTitle) : v;
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, 0.1)',
              width: 1,
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, 0.1)',
              width: 1,
            },
          },
          nameTextStyle: {
            fontFamily: 'PingFang SC',
            fontSize: '12',
            color: '#495057',
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, 0.1)',
              width: 1,
            },
          },
        },
        series: seriesData(dataAll),
        title: {
          show: false,
          text: '暂无数据',
          left: 'center',
          top: '35%',
          textStyle: {
            color: '#2CD6F7',
            fontSize: 14,
            fontFamily: 'Microsoft YaHei',
          },
        },
      };
      lineChart && lineChart.setOption(option);
    }
  }, [xAxisData, dataKey, dataList, lineChart]);

  useEffect(() => {
    setTimeout(() => {
      lineChart && lineChart.resize();
    }, 0);
  }, [isResize, lineChart]);

  useEffect(() => {
    const chart = init(document.getElementById(containerId + 'line-chart'), 'default', {
      locale: 'ZH',
    });
    setLineChart(chart);
    window.addEventListener('resize', () => chart.resize());
    return () => {
      window.removeEventListener('resize', () => chart.resize());
    };
  }, []);
  return <div id={containerId + 'line-chart'} style={{ height: height, width: width }}></div>;
};

export default LineChart;
