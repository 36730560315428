/*
 * @Description:
 * @Author: chenzhen
 * @Date: 2024-06-13 12:35:23
 * @LastEditors: chenzhen
 */
export const getTooltipBackground = content => {
  return `<div style="position: relative;box-sizing: border-box;border-radius: 2px;min-width: 189px">
  <div style="position: absolute;box-sizing: border-box;height: 100%;width: 100%;background: rgba(0, 5, 13, 0.4);border: 1px solid rgba(35, 239, 226, 0.4);backdrop-filter: blur(10px);z-index: 0;"></div>
  <div style=" box-sizing: border-box;width: calc(100% + 2px);border-radius: 2px 2px 0 0;position: absolute;top: -1px;left: -1px;height: 13px;border: rgba(56, 225, 255, 0.6) solid 2px;border-bottom: none;">
    <div style="background: rgba(56, 225, 255, 0.6);width: 2px;height: 3px;position: absolute;right: -2px;top: 11px;"></div>
  </div>
  <div style=" background: rgba(56, 225, 255, 0.6);width: 68px;height: 3px;position: absolute;top: 3px;left: 3px;"></div>
  <div style="background: rgba(56, 225, 255, 0.6);width: 68px;height: 3px;position: absolute;bottom: -1px;right: 15px;"></div>
  <div style="  width: 11px;height: 17px;border-radius: 0 0 0 3px;border: rgba(56, 225, 255, 0.6) solid 2px;border-top: none;border-right: none;position: absolute;bottom: -1px;left: -1px;"></div>
  <div style="  width: 10px;height: 16px;border-radius: 0 0 3px 0;border: rgba(56, 225, 255, 0.6) solid 2px;border-left: none;border-top: none;position: absolute;bottom: -1px;right: -1px;"></div>
  <div style="position: relative;padding: 10px;z-index: 1;">${content}</div>
</div>`;
};
