export const chartColor = [
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(124, 255, 178, 1)',
      },
      {
        offset: 1,
        color: 'rgba(124, 255, 178, 0.5)',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(74, 213, 246, 1)',
      },
      {
        offset: 1,
        color: 'rgba(74, 213, 246, 0.5)',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(221, 121, 255, 1)',
      },
      {
        offset: 1,
        color: 'rgba(221, 121, 255, 0.5)',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(79, 134, 253, 1)',
      },
      {
        offset: 1,
        color: 'rgba(79, 134, 253, 0.5)',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(250, 137, 61, 1)',
      },
      {
        offset: 1,
        color: 'rgba(250, 137, 61, 0.5)',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(238, 230, 138, 1)',
      },
      {
        offset: 1,
        color: 'rgba(238, 230, 138, 0.5)',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#5470c6',
      },
      {
        offset: 1,
        color: '#5470c605',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#91cc75',
      },
      {
        offset: 1,
        color: '#91cc7505',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#fac858',
      },
      {
        offset: 1,
        color: '#fac85805',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#ee6666',
      },
      {
        offset: 1,
        color: '#ee666605',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#73c0de',
      },
      {
        offset: 1,
        color: '#73c0de05',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#3ba272',
      },
      {
        offset: 1,
        color: '#3ba27205',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#fc8452',
      },
      {
        offset: 1,
        color: '#fc845205',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#9a60b4',
      },
      {
        offset: 1,
        color: '#9a60b405',
      },
    ],
  },
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#ea7ccc',
      },
      {
        offset: 1,
        color: '#ea7ccc05',
      },
    ],
  },
];
