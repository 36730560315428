/*
 * @Description: 柱状图
 * @Author: chenzhen
 * @Date: 2024-11-04 14:45:13
 * @LastEditors: chenzhen
 */
import React from 'react';
import BarChart from 'components/BarChart';
import LineChart from 'components/LineChart';
import { getTooltipBackground } from 'components/GlobalTooltip/getTooltipBackground';
import CircularDiagram from 'components/CircularDiagram';
import { decimaltoPercentage } from 'utils/index';

const getHistoricalBarTooltip = (params, title) => {
  const color = params?.find(v => v?.data?.color)?.data?.color;
  const content = `<div>
            <div style="color: ${color};line-height: 33px;font-size: 14px;">
            ${params[params.length - 1]?.name}：
              ${
                title === '占比'
                  ? decimaltoPercentage(params[params.length - 1]?.data?.value, '%', 2)
                  : params[params.length - 1]?.data?.value
              }
            </div>
          </div>`;
  return getTooltipBackground(content);
};

const getHistoricalLineTooltip = (params, title) => {
  const dom = params?.map(
    i =>
      `<div style="color: ${i?.color};line-height: 21px;font-size: 14px;">${i?.seriesName}：${
        title === '占比' ? decimaltoPercentage(i?.data, '%', 2) : i?.data
      }</div>`
  );
  const content = `<div>
              <div style="color: rgb(44, 214, 247, 0.8);font-size: 16px;line-height: 21px;font-weight: bold;">
                ${params[params.length - 1]?.name}
              </div>
              ${dom.join('')}`;
  return getTooltipBackground(content);
};

const getHistoricalCircularTooltip = params => {
  const content = `<div>
  <div style="font-size: 16px;line-height: 21px;font-weight: bold; color:rgb(44, 214, 247)">
    ${params?.name}
  </div>
  <div style="line-height: 33px;font-size: 14px; color:rgb(44, 214, 247)">
  ${params?.name}：${params?.value}
  </div>
  <div style="font-size: 14px; color:rgb(44, 214, 247)">
  占比：${params?.percent.toFixed(2)}%
  </div>
</div>`;
  return getTooltipBackground(content);
};

const formatterBarDataFun = (data, title: string) => {
  if (title === '占比') {
    return decimaltoPercentage(data, '%', 2);
  }
  return data;
};

const getDataList = (data, content) => {
  const xAxis = data.map(i => i?.[content?.config?.category]);
  const dataList = content?.config?.data_cols.map(item => {
    return data.map(i => {
      if (String(i?.[item]).includes('%')) {
        const decimal = i?.[item].replace('%', '') / 100;
        return { value: decimal };
      }
      return { value: i?.[item] };
    });
  });
  return {
    xAxis,
    data: dataList,
  };
};

const getLineChartDataList = (data, content) => {
  const xAxis = data.map(i => i?.[content?.config?.category]);
  const dataList = content?.config?.data_cols.map(item => {
    return {
      name: item,
      data: data.map(i => {
        if (String(i?.[item]).includes('%')) {
          const decimal = i?.[item].replace('%', '') / 100;
          return decimal;
        }
        return i?.[item];
      }),
    };
  });
  return {
    xAxis,
    data: dataList,
  };
};

// 修改数据结构
export const modifyDataStructure = content => {
  return content?.data?.map(i => {
    const name = i?.[content?.config?.category];
    const value = Number(i?.[content?.config?.data_cols[0]].split(',').join('')).toFixed(2);
    return {
      name,
      value,
    };
  });
};

const formatterPieLabel = params => {
  return params?.data?.name + '：' + params?.percent.toFixed(2) + '%';
};

// 柱状图
export const AiBarChart = ({
  dataList,
  index,
  title,
  width,
  isResize,
  isFullScreen,
}: {
  dataList;
  index;
  title?;
  width;
  isResize?;
  isFullScreen?;
}) => {
  let BarChartTitle = '数量';
  dataList?.config?.data_cols.map(item => {
    dataList?.data.forEach(i => {
      if (String(i?.[item]).includes('%')) {
        BarChartTitle = '占比';
      }
    });
  });
  return (
    <BarChart
      dataList={dataList?.data}
      width={width}
      height={isFullScreen ? '400px' : '260px'}
      currentKey={index}
      currentTitle={BarChartTitle}
      getHistoricalBarTooltip={getHistoricalBarTooltip}
      barChartId={index}
      formatterDataFun={formatterBarDataFun}
      getDataList={list => getDataList(list, dataList)}
      axisLabelInterval={0}
      dataZoom={isFullScreen ? 10 : 4}
      isResize={isResize}
      gridTop="6%"
    ></BarChart>
  );
};

// 折线图
export const AiLineChart = ({
  dataList,
  index,
  title,
  width,
  isResize,
  isFullScreen,
}: {
  dataList;
  index;
  title?;
  width;
  isResize?;
  isFullScreen?;
}) => {
  let lineChartTitle = '数量';
  dataList?.config?.data_cols.map(item => {
    dataList?.data.forEach(i => {
      if (String(i?.[item]).includes('%')) {
        lineChartTitle = '占比';
      }
    });
  });
  return (
    <LineChart
      dataList={dataList?.data}
      lineChartTitle={lineChartTitle}
      width={width}
      height={isFullScreen ? '400px' : '260px'}
      formatterXAxisFun={formatterBarDataFun}
      containerId={index}
      getHistoricalLineTooltip={getHistoricalLineTooltip}
      getLineChartDataList={list => getLineChartDataList(list, dataList)}
      dataZoom={isFullScreen ? 10 : 4}
      formatterYAxisFun={formatterBarDataFun}
      isResize={isResize}
      axisLabelInterval={0}
    ></LineChart>
  );
};

// 饼图
export const AiPieChart = ({
  dataList,
  index,
  title,
  width,
  isResize,
  isFullScreen,
}: {
  dataList;
  index;
  title?;
  width;
  isResize?;
  isFullScreen?;
}) => {
  return (
    <CircularDiagram
      containerId={index}
      width={width}
      height={isFullScreen ? '400px' : '260px'}
      data={dataList?.data}
      formatData={modifyDataStructure(dataList)}
      formatterLabel={formatterPieLabel}
      getHistoricalCircularTooltip={getHistoricalCircularTooltip}
      isResize={isResize}
      isShowLegend={isFullScreen}
    ></CircularDiagram>
  );
};
