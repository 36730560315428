/*
 * @Description: AI助手
 * @Author: chenzhen
 * @Date: 2024-10-29 15:04:08
 * @LastEditors: chenzhen
 */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IMAGE_LIST } from 'utils/enumList';
import AiChat from './aiChat';
import './index.scss';

const DraggableImage = ({ setAiFullScreen }: { setAiFullScreen }) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isOpenAiChat, setIsOpenAiChat] = useState<boolean>(false);
  const [isAmplify, setAmplify] = useState<boolean>(false);
  const [chatList, setChatList] = useState([
    { key: 'answer', content: '您好，我是您的AI助手，有什么问题都可以直接咨询我的！' },
  ]);

  const openAiChat = () => {
    setIsOpenAiChat(!isOpenAiChat);
  };

  return (
    <div
      className="assistant-decision"
      style={{
        position: 'absolute',
        bottom: isAmplify ? '0px' : '30px',
        right: isAmplify ? '15px' : '30px',
        zIndex: 99,
        cursor: 'pointer',
      }}
    >
      <div className="chat-container">
        {isOpenAiChat && (
          <AiChat
            setIsOpenAiChat={setIsOpenAiChat}
            chatList={chatList}
            setChatList={setChatList}
            isAmplify={isAmplify}
            setAmplify={setAmplify}
            setAiFullScreen={setAiFullScreen}
          />
        )}
        {!isAmplify && (
          <img
            ref={imgRef}
            src={IMAGE_LIST.AiChat}
            alt="Draggable"
            onClick={openAiChat}
            className="ai-img"
          />
        )}
      </div>
    </div>
  );
};

export default DraggableImage;
