/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-25 15:44:47
 * @LastEditors: chenzhen
 */
import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { AUTHORITY_LIST } from 'utils/enumList';
import { Spin } from 'antd';
export const routePrefix = '';
const setPrefix = (url: string) => `${routePrefix}${url}`;

const lazyload = (path: string) => {
  const Com = React.lazy(() => {
    return import(`pages/${path}`);
  });
  return (
    <React.Suspense
      fallback={
        <Spin
          tip="Loading"
          size="large"
          style={{
            width: '100%',
            height: '300px',
            marginTop: '200px',
          }}
        >
          <></>
        </Spin>
      }
    >
      <Com />
    </React.Suspense>
  );
};

/**
 * 路由列表
 * @property {bool} exact 是否严格匹配
 * @property {string} path 路径
 * @property {string} to 路径
 * @property {func} component 渲染模版
 * @property {string} redirectTo 重定向地址
 * @property {array} children 子节点
 */
// 路由
const defaultRoutes = [
  {
    path: '/homePage',
    element: lazyload('homePage'),
    key: AUTHORITY_LIST.SXSQK,
  },
  // 各地市情况
  {
    path: '/regionSituation',
    element: lazyload('regionSituation'),
    key: AUTHORITY_LIST.GDSQK,
  },
  // 保险机构概览
  {
    path: '/insuranceOverview',
    element: lazyload('insuranceOverview'),
    key: AUTHORITY_LIST.BXJGGL,
  },
  // 个人信息修改
  {
    path: '/changeUserInfo',
    element: lazyload('changeUserInfo'),
    key: AUTHORITY_LIST.USER_GRXXXG,
    children: [
      {
        path: 'changePassword',
        element: lazyload('changeUserInfo/changePassword'),
        key: AUTHORITY_LIST.USER_XGMM,
      },
      {
        path: 'personalCenter',
        element: lazyload('changeUserInfo/personalCenter'),
        key: AUTHORITY_LIST.USER_GRZX,
      },
    ],
  },
  // 统计分析
  {
    path: '/statisticalAnalysis',
    element: lazyload('statisticalAnalysis'),
    key: AUTHORITY_LIST.TJFX,
    children: [
      {
        path: 'summaryStatisticsTable',
        element: lazyload('statisticalAnalysis/summaryStatisticsTable'),
        key: AUTHORITY_LIST.TJFX_HZTJB,
      },
      {
        path: 'policyDetailsTable',
        element: lazyload('statisticalAnalysis/policyDetailsTable'),
        key: AUTHORITY_LIST.TJFX_BDMXB,
      },
      {
        path: 'claimsSettlementTable',
        element: lazyload('statisticalAnalysis/claimsSettlementTable'),
        key: AUTHORITY_LIST.TJFX_LPMXB,
      },
      {
        path: 'accidentPreventionTable',
        element: lazyload('statisticalAnalysis/accidentPreventionTable'),
        key: AUTHORITY_LIST.TJFX_SGYFMXB,
      },
      {
        path: 'securityServiceAgencies',
        element: lazyload('statisticalAnalysis/securityServiceAgencies'),
        key: AUTHORITY_LIST.TJFX_AQFWJG,
      },
      {
        path: 'declarationDetailsTable',
        element: lazyload('statisticalAnalysis/declarationDetailsTable'),
        key: AUTHORITY_LIST.TJFX_SBMXB,
      },
    ],
  },
  // 运行报告
  {
    path: '/runReport',
    element: lazyload('runReport'),
    key: AUTHORITY_LIST.YXBG,
  },
  // 评优企业
  {
    path: '/excellentEnterprise',
    element: lazyload('excellentEnterprise'),
    key: AUTHORITY_LIST.PYQY,
  },
  // 资料上传
  {
    path: '/dataUpload',
    element: lazyload('dataUpload'),
    key: AUTHORITY_LIST.ZLSC,
  },
  // 函件往来
  {
    path: '/correspondenceExchange',
    element: lazyload('correspondenceExchange'),
    key: AUTHORITY_LIST.HJWL,
  },
  // 备案
  {
    path: '/filings',
    element: lazyload('filings'),
    key: AUTHORITY_LIST.BA,
  },
  // 保司考核
  {
    path: '/insuranceAssessment',
    element: lazyload('insuranceAssessment'),
    key: AUTHORITY_LIST.BSKH,
  },
  // 管理员页面
  {
    path: '/administrator',
    element: lazyload('administrator'),
    key: AUTHORITY_LIST.ADMIN_GLYYM,
    children: [
      {
        path: 'roleManagement',
        element: lazyload('administrator/roleManagement'),
        key: AUTHORITY_LIST.ADMIN_JSGL,
      },
      {
        path: 'userManagement',
        element: lazyload('administrator/userManagement'),
        key: AUTHORITY_LIST.ADMIN_YHGL,
      },
    ],
  },
  {
    path: '/',
    element: <Navigate to="/homePage" />,
  },
];
/**
 * ## 根据权限获取页面列表
 * @param {*} authList
 * @returns
 */
export const getRoutes = authList => {
  // if (!authList.length) return defaultRoutes;

  if (!authList.length) return [];
  return defaultRoutes.filter(item => authList.includes(item.key));
};
