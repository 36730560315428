/*
 * @Description:
 * @Author: chenzhen
 * @Date: 2024-06-19 10:04:57
 * @LastEditors: chenzhen
 */
import React, { useEffect, useState } from 'react';
import { init, ECharts } from 'echarts';

const CircularDiagram = ({
  containerId,
  width,
  height,
  data,
  formatData,
  formatterLabel,
  getHistoricalCircularTooltip,
  isResize,
  isShowLegend = true,
}: {
  containerId: string;
  width: string | number;
  height: string | number;
  data;
  formatData;
  formatterLabel;
  getHistoricalCircularTooltip;
  isResize?;
  isShowLegend?: boolean;
}) => {
  const [circularDiagram, setCircularDiagram] = useState<ECharts>();
  useEffect(() => {
    if (data && formatData) {
      const option: {
        color?;
        toolbox?;
        tooltip?;
        legend?;
        dataZoom?;
        grid?;
        xAxis?;
        yAxis?;
        series?;
        title?;
      } = {
        color: [
          'rgba(79, 134, 253, 1)',
          'rgba(74, 213, 246, 1)',
          'rgba(250, 137, 61, 1)',
          'rgba(238, 230, 138, 1)',
          'rgba(124, 255, 178, 1)',
          'rgba(221, 121, 255, 1)',
          'rgba(5, 192, 145, 1)',
          'rgba(255, 109, 196, 1)',
          'rgba(79, 86, 253, 1)',
          'rgba(255, 110, 118, 1)',
          'rgba(141, 72, 227, 1)',
        ],
        toolbox: {
          show: false,
          left: 'auto',
          right: '1%',
          top: 'auto',
          bottom: '1%',
          feature: {
            saveAsImage: {
              name: '环形图',
              backgroundColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#0c1f40',
                  },
                  {
                    offset: 1,
                    color: '#081224',
                  },
                ],
              },
              excludeComponents: ['toolbox'],
              title: '保存为图片',
              pixelRatio: 1,
              iconStyle: {
                borderColor: '#39C6F9',
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#fefefe',
                },
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderWidth: 0,
          padding: 2,
          extraCssText: 'box-shadow: none !important;',
          formatter: params => getHistoricalCircularTooltip(params),
        },
        legend: {
          width: 0,
          left: 'auto',
          right: '0',
          bottom: 'auto',
          top: 'center',
          show: isShowLegend,
          type: 'scroll',
          height: 224,
          orient: 'vertical',
          itemStyle: {
            opacity: 1,
          },
          scrollDataIndex: 0,
          pageTextStyle: {
            fontFamily: 'Microsoft YaHei',
            fontSize: 10,
            fontWeight: '400',
            fontStyle: 'normal',
            color: 'rgba(255, 255, 255, 1)',
          },
          pageIconColor: 'rgba(74, 213, 246, 1)',
          pageIconInactiveColor: 'rgba(74, 213, 246, 0.5)',
          itemGap: 10,
          itemWidth: 25,
          itemHeight: 14,
          selectedMode: true,
          animation: false,
          textStyle: {
            fontFamily: 'Microsoft YaHei',
            fontSize: 10,
            fontWeight: '400',
            fontStyle: 'normal',
            color: 'rgba(255, 255, 255, 0.8)',
          },
        },
        series: [
          {
            type: 'pie',
            sampling: 'average',
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: 'outside',
              fontFamily: 'Microsoft YaHei',
              fontSize: 11,
              fontWeight: '400',
              fontStyle: 'normal',
              color: 'rgba(234, 234, 234, 1)',
              lineHeight: 11,
              formatter: params => formatterLabel(params),
            },
            labelLayout: {
              hideOverlap: true,
            },
            emphasis: {},
            radius: ['28%', '68%'],
            itemStyle: {
              borderColor: 'rgba(12, 31, 64, 1)',
              borderWidth: 2,
              borderRadius: 4,
            },
            roseType: false,
            left: 0,
            right: 40,
            bottom: 0,
            top: 0,
            containLabel: true,
            name: formatData?.[0]?.alias?.name,
            data: formatData && formatData,
          },
        ],
      };
      circularDiagram && circularDiagram.setOption(option);
    }
  }, [circularDiagram, data, formatData]);

  useEffect(() => {
    setTimeout(() => {
      circularDiagram && circularDiagram.resize();
    }, 0);
  }, [isResize, circularDiagram]);

  useEffect(() => {
    const dom = 'circular-diagram-' + containerId;
    const chart = init(document.getElementById(dom), 'default', {
      locale: 'ZH',
    });
    setCircularDiagram(chart);
    window.addEventListener('resize', () => chart.resize());
    return () => {
      window.removeEventListener('resize', () => chart.resize());
    };
  }, []);
  return (
    <div id={'circular-diagram-' + containerId} style={{ width: width, height: height }}></div>
  );
};

export default CircularDiagram;
