/*
 * @Description: 柱状图
 * @Author: chenzhen
 * @Date: 2024-06-14 15:46:21
 * @LastEditors: chenzhen
 */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { init, ECharts } from 'echarts';
import { chartColor } from './chartColor';

const BarChart = ({
  dataList,
  height,
  width,
  currentKey,
  currentTitle,
  barChartId,
  formatterDataFun,
  getHistoricalBarTooltip,
  getDataList, // 获取x轴及data
  axisLabelRotate, // 标签旋转角度
  axisLabelInterval, // 标签显示间隔
  dataZoom, // 滚动条一页显示几条数据
  valueKey,
  gridTop, // 顶部留白
  yAxisMax, // 固定设置y轴最大值
  isLabelShow,
  minInterval, // 最小刻度间隔
  isResize, // 是否重新加载柱状图
}: {
  dataList;
  height: string;
  width: string;
  currentKey: string | undefined;
  currentTitle;
  barChartId: string;
  formatterDataFun;
  getHistoricalBarTooltip;
  getDataList;
  axisLabelRotate?: number;
  axisLabelInterval?: number;
  dataZoom?: number;
  valueKey?: string;
  gridTop?: string;
  yAxisMax?: number;
  isLabelShow?: boolean;
  minInterval?: number;
  isResize?;
}) => {
  const [barChart, setBarChart] = useState<ECharts>();

  const seriesData = dataAll => {
    if (dataAll?.data.length === 1) {
      return [
        {
          type: 'bar',
          sampling: 'average',
          barGap: 0.1,
          barWidth: 26,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#2CD6F7',
                },
                {
                  offset: 1,
                  color: 'rgba(44, 214, 247,0)',
                },
              ],
            },
            borderType: 'solid',
            borderWidth: 0,
            borderColor: '#ced4da',
          },
          label: {
            show: true,
            position: 'top',
            fontFamily: 'Microsoft YaHei',
            fontSize: 10,
            fontWeight: 'normal',
            fontStyle: 'normal',
            color: '#38E1ff',
            formatter: v => {
              return formatterDataFun(v?.value, currentTitle);
            },
          },
          labelLayout: {
            hideOverlap: true,
          },
          markLine: {},
          markArea: {},
          emphasis: {
            itemStyle: {
              color: '#38E1ff',
            },
            label: {
              color: '#38E1ff',
            },
          },
          name: currentTitle,
          data: dataAll?.data?.[0],
        },
      ];
    } else {
      const seriesList = dataAll?.data.map((item, index) => {
        return [
          {
            type: 'bar',
            sampling: 'average',
            barGap: 0.1,
            barWidth: 18,
            label: {
              show: isLabelShow === false ? isLabelShow : true,
              color: chartColor[index]?.colorStops?.[0]?.color,
              position: 'top',
              fontFamily: 'Microsoft YaHei',
              fontSize: 10,
              fontWeight: 'normal',
              fontStyle: 'normal',
              formatter: v => {
                return formatterDataFun(v?.value, currentTitle);
              },
            },
            labelLayout: {
              hideOverlap: true,
            },
            markLine: {},
            markArea: {},
            emphasis: {
              itemStyle: {
                color: 'inherit',
              },
              label: {
                show: isLabelShow === false ? isLabelShow : true,
              },
            },
            name: item?.[0]?.alias?.name,
            data: item,
          },
        ];
      });
      const series = seriesList.map(i => i?.[0]);
      return series;
    }
  };

  useEffect(() => {
    const dataAll = getDataList(dataList, currentKey, valueKey);
    if (dataList) {
      // 配置项
      const option: {
        color?;
        toolbox?;
        tooltip?;
        legend?;
        dataZoom?;
        grid?;
        xAxis?;
        yAxis?;
        series?;
        title?;
      } = {
        color: chartColor,
        toolbox: {
          show: false,
          left: 'auto',
          right: '1%',
          top: 'auto',
          bottom: '1%',
          feature: {
            saveAsImage: {
              name: '柱状图',
              backgroundColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#0c1f40',
                  },
                  {
                    offset: 1,
                    color: '#081224',
                  },
                ],
              },
              excludeComponents: ['toolbox'],
              title: '保存为图片',
              pixelRatio: 1,
              iconStyle: {
                borderColor: '#39C6F9',
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#fefefe',
                },
              },
            },
          },
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderWidth: 0,
          padding: 2,
          extraCssText: 'box-shadow: none !important;',
          textStyle: {
            color: 'rgba(44, 214, 247, 0.8);',
          },
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: 'rgba(35,239,226, 0.75)',
            },
          },
          formatter: params => getHistoricalBarTooltip(params, currentTitle),
        },
        legend: {
          right: 8,
          top: 5,
          show: dataAll?.data.length === 1 ? false : true,
          height: null,
          orient: 'horizontal',
          selectedMode: true,
          textStyle: {
            fontFamily: 'PingFang SC',
            fontSize: '10',
            fontWeight: 'normal',
            fontStyle: 'normal',
            color: 'rgba(255, 255, 255, 0.8)',
          },
        },
        dataZoom: dataZoom && [
          {
            type: 'slider',
            handleSize: '100%',
            orient: 'horizontal',
            xAxisIndex: 0,
            showDetail: false,
            backgroundColor: 'rgba(20, 43, 86, 1)',
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            selectedDataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            borderColor: 'rgba(0,0,0,0)',
            fillerColor: 'rgba(44, 214, 247, 0.8)',
            handleStyle: {
              borderCap: 'round',
              opacity: 0,
            },
            brushSelect: false,
            height: 8,
            startValue: 0,
            endValue: dataZoom,
            zoomLock: false,
            left: 'auto',
            bottom: '5',
          },
        ],
        grid: {
          left: '2%',
          right: '5%',
          bottom: '5%',
          top: gridTop || '15%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          tooltip: {
            show: true,
          },
          data: dataAll?.xAxis,
          axisLabel: {
            show: true,
            rotate: axisLabelRotate || 0,
            interval: axisLabelInterval || 0,
            overflow: 'break',
            fontFamily: 'Microsoft YaHei',
            fontSize: 10,
            fontWeight: 'normal',
            fontStyle: 'normal',
            color: '#FFFFFF',
            lineHeight: 12,
            hideOverlap: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              width: 1,
              color: '#ced4da',
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              type: 'solid',
              width: 1,
              color: '#ced4da',
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              width: 1,
              color: '#ced4da',
            },
          },
          axisPointer: {
            show: true,
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(255,255,255,0.1)',
            },
            label: {
              show: false,
            },
          },
        },
        yAxis: {
          type: 'value',
          name: null,
          nameLocation: 'center',
          nameGap: 20,
          nameRotate: 90,
          inverse: false,
          minInterval: minInterval || 0,
          max: yAxisMax || undefined,
          axisLabel: {
            show: true,
            fontFamily: 'Microsoft YaHei',
            fontSize: 10,
            fontWeight: 'normal',
            fontStyle: 'normal',
            color: '#FFFFFF',
            formatter: v => {
              return formatterDataFun(v, currentTitle);
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              width: 1,
              color: '#ced4da',
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              type: 'solid',
              width: 1,
              color: '#ced4da',
            },
          },
          nameTextStyle: {
            fontFamily: 'Microsoft YaHei',
            fontSize: 10,
            fontWeight: 'normal',
            fontStyle: 'normal',
            color: '#FFFFFF',
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              width: 1,
              color: '#ced4da',
            },
          },
        },
        // 默认展示单指标柱状图
        series: seriesData(dataAll),
        title: {
          show: false,
          text: '暂无数据',
          left: 'center',
          top: '35%',
          textStyle: {
            color: '#2CD6F7',
            fontSize: 14,
            fontFamily: 'Microsoft YaHei',
          },
        },
      };

      barChart && barChart.setOption(option);
    }
  }, [barChart, dataList, currentKey, valueKey, isLabelShow]);

  useEffect(() => {
    setTimeout(() => {
      barChart && barChart.resize();
    }, 0);
  }, [isResize, barChart]);

  useEffect(() => {
    const dom = 'bar-chart-' + barChartId;
    const chart = init(document.getElementById(dom), 'default', {
      locale: 'ZH',
    });
    setBarChart(chart);
    window.addEventListener('resize', () => chart.resize());
    return () => {
      window.removeEventListener('resize', () => chart.resize());
    };
  }, []);
  return <div id={'bar-chart-' + barChartId} style={{ width: width, height: height }}></div>;
};

export default BarChart;
