/*
 * @Description: AI助手
 * @Author: chenzhen
 * @Date: 2024-11-05 15:43:17
 * @LastEditors: chenzhen
 */
import { axiosSubmit } from 'api/axios';

/**
 * ## 智能助手问答接口
 * @question 问题
 * @returns
 */
export const getAiChatAnswer = params => axiosSubmit.get(`/assistant/ai/chat`, { params });

/**
 * ## 智能助手预设问题列表
 * @returns
 */
export const getPresetQuestion = () => axiosSubmit.get(`/assistant/ai/frequently_question`);

/**
 * ## 智能助手相似度计算
 * @returns
 */
export const getQuestionSimilarity = params =>
  axiosSubmit.get(`/assistant/ai/similarity/query`, { params });
